import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './Mint.css';
import {ADDRESS, ABI} from "../config.js"
import {ADDRESS as ADDRESSTEST, ABI as ABITEST} from "../testconfig.js"
import {ADDRESS as ADDRESSTESTTWO, ABI as ABITESTTWO} from "../testconfigtwo.js"






function Mint() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_nuggets, set_how_many_nuggets] = useState(1)

    const [nuggetContract, setNuggetContract] = useState(null)//
    const [walkerContract, setWalkerContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)

    const [saleStarted, setSaleStarted] = useState(false)//

    const [getPrice, setNuggetPrice] = useState(0)//

    const [redeemableState, setRedeemableState] = useState([]);
    const [checkedNuggets, setCheckedNuggets] = useState([]);

//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    useEffect(() => {
        setCheckedNuggets(
            checkedNuggets.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setRedeemableState(
            redeemableState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);
    useEffect(() => {
        nuggetsOwned(walletAddress)



    }, [signedIn]);

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)

        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const nuggetContract = new window.web3.eth.Contract(ABITEST, ADDRESSTEST)
        setNuggetContract(nuggetContract)
        const walkerContract = new window.web3.eth.Contract(ABITESTTWO, ADDRESSTESTTWO)
        setWalkerContract(walkerContract)



        const getPrice = 10000000000000000
        setNuggetPrice(getPrice)

        const totalSupply = await nuggetContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)



    }

    async function mintDuck(how_many_nuggets) {
        if (walkerContract) {


            const price = 0;
            const nugIds = checkedNuggets.map((checkedNuggets) => {
                return checkedNuggets.id
            })
            const gasAmount = await walkerContract.methods.mediaClaim(nugIds).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            walkerContract.methods
                .mediaClaim(nugIds)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };
    async function mintMedia(how_many_nuggets) {
        if (walkerContract) {


            const price = (10000000000000000 * how_many_nuggets);
            const nugIds = checkedNuggets.map((checkedNuggets) => {
                return checkedNuggets.id
            })
            const gasAmount = await walkerContract.methods.mintAdditionalMedia(how_many_nuggets).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            walkerContract.methods
                .mintAdditionalMedia(how_many_nuggets)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})

            setCheckedNuggets(checkedNuggets => [])
            setRedeemableState(redeemableState => [])
            let f = true;
            let k = 0;
            for (let i = 0; i < nuggetsOfOwner.length; i++) {
                console.log(nuggetsOfOwner)
                f = await walkerContract.methods.getMintRedeemed(nuggetsOfOwner[i]).call()

                if (f == false) {
                    setRedeemableState(redeemableState => [ ...redeemableState, {
                        select: false,
                        id: nuggetsOfOwner[i],
                        nugget: "Claim ETHWALKER " + nuggetsOfOwner[i],
                        number: nuggetsOfOwner[i]
                    }])
                    k++;


                } else {

                }
            }


        }else {
            console.log("Wallet not connected")
        }




    };



const handleClick = () => {
    //console.log('Hello there!');
    mintDuck(how_many_nuggets)

};
const handleClick2 = () => {
    //console.log('Hello there!');
    mintMedia(how_many_nuggets)

};
return (
    <div className="daddy">
        <div className='new-body'>
            <div className='hero-container-Private'>



                <div id="mint" className="flex">



                </div>


            </div>

        </div>
        <div className="FAQ-container">
        <div className='hero-btns'>
        {signedIn?
        <Button
            className='btns'
            buttonStyle='btn-skull-yellow'
            buttonSize='btn--large--skull'
            onClick={handleClick}
        >
        CLAIM {checkedNuggets.length} Media NFTs For Free(You Pay GAS)
        </Button>
        : <Button
            onClick={signIn}
            className='btns'
            buttonStyle='btn-skull-yellow'
            buttonSize='btn--large--skull'
        >Click to Connect Wallet with Metamask
        </Button>
        }
        </div>
        {signedIn?
        <div>
        <h1>Claim Media For:</h1>
        <span>Limit 20 per TX</span>
        </div>
        :
        <div></div>
        }
                <table className='table1'>

                    <tbody>
                        {
                            redeemableState.map((d, i) => (
                                <label className='box-label'>
                            <tr key={d.id}>

                                <th scope="row">

                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        setRedeemableState(
                                            redeemableState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newCheckedNuggets = [ ...checkedNuggets];
                                                        const index = checkedNuggets.findIndex((checkedNuggets)=> checkedNuggets.id === data.id);
                                                        newCheckedNuggets.splice(index, 1);
                                                        setCheckedNuggets(newCheckedNuggets);

                                                    }


                                                    data.select = checked;

                                                    setCheckedNuggets(checkedNuggets => [ ...checkedNuggets, {
                                                        id: data.id,

                                                    }])
                                                    if (checked === false) {

                                                        const newCheckedNuggets = [ ...checkedNuggets];
                                                        const index = checkedNuggets.findIndex((checkedNuggets)=> checkedNuggets.id === data.id);
                                                        newCheckedNuggets.splice(index, 1);
                                                        setCheckedNuggets(newCheckedNuggets);

                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>

                                </th>
                                <td>{d.nugget}</td>

                            </tr>
                            </label>

                        ))}
                    </tbody>
                </table>

        <h2>What is this?</h2>

        <p>If you hold a Season One ETH Walker NFT, you may claim a Season One Media NFT for free.<br/>
        You may mint one Media NFT per Walker you own.<br/><br/>
        Non-holders may also mint the Season One Media NFT for 0.01 ETH</p>
        <div className='minting-sec'>
        <div>

        <h1>Don't have Walkers to claim? Mint Season One Media Below</h1>
        <span className="flex">Mint</span>
                      <input
                                          type="number"
                                          style={{ marginLeft: '5px',marginRight: '5px' }}

                                          min="1"
                                          max="10"
                                          text="black"
                                          value={how_many_nuggets}
                                          onChange={ e => set_how_many_nuggets(e.target.value) }
                                          name=""
                                      />

                <span className="flex">Season One Media (Limit 20 per TX)</span>
            </div>
        <div className='hero-btns'>
        {signedIn?
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={handleClick2}
        >
        MINT {how_many_nuggets} Media NFTs {(10000000000000000 * how_many_nuggets) / (10 ** 18)} ETH + GAS
        </Button>
        : <Button
            onClick={signIn}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
        >Click to Connect Wallet with Metamask
        </Button>
        }
        </div>

        <h2>Contract Verified on Etherscan:</h2>
        <p><a href="https://etherscan.io/address/0xEA3670f81b7ccE94477B214185D9DD49298FE932" link="black" vlink="red">0xEA3670f81b7ccE94477B214185D9DD49298FE932</a></p>


        </div>
        </div>
    </div>



);
}
export default Mint;
