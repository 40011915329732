import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import img1 from '../assets/ETH_WALKERS_LOGO_WHITE.png';

function Navbar() {
    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>

                    <img className="card__image" src={img1} alt="not shown"/>

                </div>
            </nav>
        </>
    )
}

export default Navbar;
